var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
    _c("div", { staticClass: "vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" }, [
      _vm.visitor
        ? _c("div", { staticClass: "visitor-info" }, [
            _c(
              "div",
              { staticClass: "visitor-info__heading" },
              [
                _c(
                  "div",
                  { staticClass: "visitor-info__heading__main" },
                  [
                    _vm.visitor.b2b
                      ? _c("CompanyIcon")
                      : _c("PrivateVisitorIcon"),
                    _c(
                      "div",
                      { staticClass: "visitor-info__heading__main__details" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__heading__main__details__label",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.visitor.b2b
                                  ? _vm.$t("vue.companyVisitor")
                                  : _vm.$t("vue.privateVisitor")
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__heading__main__details__title",
                          },
                          [_vm._v(_vm._s(_vm.companyName))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.visitor.b2b
                  ? _c(
                      "a",
                      {
                        staticClass: "visitor-info__heading__button-google",
                        attrs: {
                          href:
                            "https://www.google.com/search?q=" +
                            _vm.companyName,
                          target: "_blank",
                        },
                      },
                      [
                        _c("GoogleIcon", {
                          staticClass:
                            "visitor-info__heading__button-google__icon",
                        }),
                        _vm._v(_vm._s(_vm.$t("vue.searchOnGoogle"))),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("vs-button", {
                  staticClass: "visitor-info__heading__button-close",
                  class: { "ml-auto": !_vm.visitor.b2b },
                  attrs: {
                    color: "#3034F7",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-x",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeVisitor()
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "visitor-info__details" }, [
              _c("div", { staticClass: "visitor-info__details__list" }, [
                _c(
                  "div",
                  { staticClass: "visitor-info__details__list__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.country")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__value",
                      },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "pl-0",
                            attrs: {
                              text: _vm.countryName(_vm.visitor.country),
                              position: "top",
                            },
                          },
                          [
                            _vm.visitor.country
                              ? _c("img", {
                                  attrs: {
                                    width: "20",
                                    alt: _vm.countryName(_vm.visitor.country),
                                    src:
                                      "https://storage.googleapis.com/pathadvice-app.appspot.com/flags/" +
                                      _vm.visitor.country +
                                      ".svg",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "visitor-info__details__list__item",
                    staticStyle: { width: "36%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.city")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__value",
                      },
                      [_vm._v(_vm._s(_vm.visitor.city || "-"))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "visitor-info__details__list__item",
                    staticStyle: { width: "24%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.firstseen")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__value",
                      },
                      [_vm._v(_vm._s(_vm.firstSeen))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "visitor-info__details__list__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.lastSeen")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__value",
                        class: {
                          "visitor-info__details__list__item__value--online":
                            _vm.visitor.online,
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.visitor.online
                                ? _vm.$t("vue.currentlyOnline")
                                : _vm.lastSeen
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "visitor-info__details__list__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.totalPageViews")))]
                    ),
                    _vm.visitorPageVisits && _vm.visitorPageVisits.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__list__item__value",
                          },
                          [_vm._v(_vm._s(_vm.totalPageViews))]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__list__item__value",
                          },
                          [_vm._v("-")]
                        ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "visitor-info__details__list__item",
                    staticStyle: { width: "35%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.lastPageLocation")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visitor-info__details__list__item__value",
                        staticStyle: { "word-wrap": "break-word" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.removeHttpPrefix(
                                _vm.visitorPageVisits &&
                                  _vm.visitorPageVisits.length > 0
                                  ? _vm.visitorPageVisits[0].location
                                  : _vm.visitor.location
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "visitor-info__details__sections" }, [
                _c(
                  "div",
                  { staticClass: "visitor-info__details__sections__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-info__details__sections__item__heading",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__heading__icon-wrapper",
                            staticStyle: { background: "#1ef8f0" },
                          },
                          [
                            _c("StarIcon", {
                              staticClass:
                                "visitor-info__details__sections__item__heading__icon",
                            }),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n\n              " +
                            _vm._s(_vm.$t("vue.pageViews")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm.isLoadingPageVisits
                      ? _c("placeholder-text-small")
                      : _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-info__details__sections__item__body__column",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "visitor-info__details__sections__item__body__column__label",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("vue.page")))]
                                ),
                                _vm.visitorPageVisits &&
                                _vm.visitorPageVisits.length > 0
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "visitor-info__details__sections__item__body__column__list",
                                      },
                                      _vm._l(
                                        _vm.visitorPageVisits,
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.location,
                                              staticClass:
                                                "visitor-info__details__sections__item__body__column__list__item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.removeHttpPrefix(
                                                      item.location
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "visitor-info__details__list__item__value",
                                      },
                                      [_vm._v("-")]
                                    ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-info__details__sections__item__body__column",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "visitor-info__details__sections__item__body__column__label",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("vue.visits")))]
                                ),
                                _vm.visitorPageVisits &&
                                _vm.visitorPageVisits.length > 0
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "visitor-info__details__sections__item__body__column__list",
                                      },
                                      _vm._l(
                                        _vm.visitorPageVisits,
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              key:
                                                item.location +
                                                " " +
                                                item.visits,
                                              staticClass:
                                                "visitor-info__details__sections__item__body__column__list__item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.visits) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "visitor-info__details__list__item__value",
                                      },
                                      [_vm._v("-")]
                                    ),
                              ]
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "visitor-info__details__sections__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-info__details__sections__item__heading",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__heading__icon-wrapper",
                            staticStyle: { background: "#f1a342" },
                          },
                          [
                            _c("StarIcon", {
                              staticClass:
                                "visitor-info__details__sections__item__heading__icon",
                            }),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n\n              " +
                            _vm._s(_vm.$t("vue.visitorInformation")) +
                            "\n\n              "
                        ),
                        _vm.visitor
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-info__details__sections__item__heading__device-icons",
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getDeviceInfo(_vm.visitor.userAgent)
                                        ? _vm.getDeviceInfo(
                                            _vm.visitor.userAgent
                                          ).browser.html
                                        : null
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "mx-3",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getDeviceInfo(_vm.visitor.userAgent)
                                        ? _vm.getDeviceInfo(
                                            _vm.visitor.userAgent
                                          ).os.html
                                        : null
                                    ),
                                  },
                                }),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getDeviceInfo(_vm.visitor.userAgent)
                                        ? _vm.getDeviceInfo(
                                            _vm.visitor.userAgent
                                          ).platform.html
                                        : null
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-info__details__sections__item__body",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__body__column",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-info__details__sections__item__body__column__label",
                              },
                              [_vm._v(_vm._s(_vm.$t("vue.contactDetails")))]
                            ),
                            _vm.visitor.companyName
                              ? _c("div", [
                                  _vm._v(_vm._s(_vm.visitor.companyName)),
                                ])
                              : _vm._e(),
                            _vm.visitor.city
                              ? _c("div", [_vm._v(_vm._s(_vm.visitor.city))])
                              : _vm._e(),
                            _vm.visitor.street
                              ? _c("div", [_vm._v(_vm._s(_vm.visitor.street))])
                              : _vm._e(),
                            _vm.visitor.zipcode
                              ? _c("div", [_vm._v(_vm._s(_vm.visitor.zipcode))])
                              : _vm._e(),
                            _vm.contactDetails
                              ? [
                                  _vm.contactDetails.name
                                    ? _c("div", [
                                        _vm._v(_vm._s(_vm.contactDetails.name)),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "visitor-info__details__sections__item__body__column__contact",
                                    },
                                    [
                                      _vm.contactDetails.phone
                                        ? _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "tel:" +
                                                    _vm.contactDetails.phone,
                                                },
                                              },
                                              [
                                                _c("VerticalPhoneIcon", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    color: "#767480",
                                                    width: 16,
                                                    height: 16,
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.contactDetails.phone
                                                    )
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.contactDetails.email
                                        ? _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "mailto:" +
                                                    _vm.contactDetails.email,
                                                },
                                              },
                                              [
                                                _c("MailIcon", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    color: "#767480",
                                                    width: 16,
                                                    height: 16,
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.contactDetails.email
                                                    )
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            !_vm.visitor.companyName &&
                            !_vm.visitor.city &&
                            !_vm.visitor.street &&
                            !_vm.visitor.zipcode &&
                            !_vm.contactDetails
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "visitor-info__details__list__item__value",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("vue.noDetailsAvailable")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "visitor-info__details__sections__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-info__details__sections__item__heading",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__heading__icon-wrapper",
                            style:
                              "background: " +
                              (_vm.visitor.isVegaWidgetEnabled &&
                              _vm.isVisitorScores
                                ? "#FE124E"
                                : "#C4C4C4") +
                              ";",
                          },
                          [
                            _vm.visitor.isVegaWidgetEnabled
                              ? _c("PulseIcon", {
                                  staticClass:
                                    "visitor-info__details__sections__item__heading__icon",
                                })
                              : _c("LockIcon", {
                                  staticClass:
                                    "visitor-info__details__sections__item__heading__icon",
                                  attrs: {
                                    color: "#fff",
                                    width: 12,
                                    height: 12,
                                  },
                                }),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n\n              " +
                            _vm._s(_vm.$t("vue.statusHistory")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-info__details__sections__item__body",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-info__details__sections__item__body__column",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-info__details__sections__item__body__column__label",
                              },
                              [_vm._v(_vm._s(_vm.$t("vue.latestStatus")))]
                            ),
                            _c("VisitorStatusIconWithText", {
                              staticClass: "mt-3",
                              style:
                                "width: 125px; " +
                                (_vm.visitor.isVegaWidgetEnabled &&
                                _vm.isVisitorScores
                                  ? ""
                                  : "background: rgba(196, 196, 196, 0.2); color: blue") +
                                ";",
                              attrs: {
                                colorText: "#C4C4C4",
                                score: _vm.visitorStatusScoreValue,
                                online: true,
                                isVegaWidgetEnabled: true,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "visitor-info__chart-wrapper" },
              [
                _c("placeholder-text-small", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoadingVisitorsScore,
                      expression: "isLoadingVisitorsScore",
                    },
                  ],
                }),
                _c("div", { attrs: { id: "chart-visitor-score" } }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }